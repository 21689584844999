import React, { useState } from "react";
import clsx from "clsx";
import { Text, Container, Image, Button, ButtonAlt } from "@atoms";
import { m } from "framer-motion";
import { AccordionItem } from "@molecules";

const ImageCopy = ({
  heading,
  button,
  secondaryLink,
  image,
  color,
  reverse,
  shape,
  blocks,
  className: _className,
}) => {
  const transition = { duration: 2.5, ease: [0.22, 1, 0.36, 1] };
  const [open, setOpen] = useState(0);

  return (
    <section
      className={clsx("my-10 sm:mb-20 sm:mt-20 md:mt-32", _className)}
    >
      <Container>
        <div
          className={clsx("md:gap-18 grid md:grid-cols-2 lg:gap-24", {
            "gap-4": shape === "square",
            "gap-12": shape !== "square",
          })}
        >
          {/* text container */}
          <div
            className={clsx(
              "relative z-20 flex flex-col items-start justify-center",
              {
                "order-2": reverse,
                "order-2 md:order-1": !reverse,
              }
            )}
          >
            <Text variant="h2" className="text-purple">
              {heading}
            </Text>
            <div>
              {blocks?.map((block, i) => {
                return (
                  <AccordionItem
                    key={block.uid}
                    {...block}
                    showBorders={false}
                    open={open === i}
                    setOpen={() => setOpen(i)}
                  />
                );
              })}
            </div>
            <div className="flex flex-wrap items-center gap-3">
              {button?.url && (
                <div className="mt-8">
                  <Button to={button.url} color="purple" size="sm">
                    {button.text}
                  </Button>
                </div>
              )}
              {secondaryLink?.url && (
                <div className="mt-8">
                  <ButtonAlt
                    color="pink"
                    to={secondaryLink?.url}
                    className="text-pink"
                  >
                    {secondaryLink.text}
                  </ButtonAlt>
                </div>
              )}
            </div>
          </div>
          {/* image container */}
          <div
            className={clsx(
              "relative flex h-full min-h-[40vh] items-end md:max-h-[20rem] md:items-stretch lg:max-h-[26rem] xl:max-h-[32rem]",
              {
                "order-1 justify-end": reverse,
                "order-1 justify-start md:order-2": !reverse,
              }
            )}
          >
            <div
              className={clsx(
                "relative z-10 w-full overflow-hidden shadow-xl",
                {
                  "h-48 sm:h-64 md:h-auto": shape !== "shapes",
                  "rounded-3xl": shape === "rounded",
                  "h-full rounded-t-3xl": shape === "shapes",
                  "rounded-bl-3xl rounded-br-6xl":
                    reverse && shape === "shapes",
                  "rounded-bl-6xl rounded-br-3xl":
                    !reverse && shape === "shapes",
                  "bg-pink": color === "pink",
                  "bg-teal": color === "teal",
                  "bg-yellow": color === "yellow",
                }
              )}
            >
              <Image
                image={image}
                fill
                className={clsx({
                  "rounded-br-6xl": shape === "square",
                  "rounded-3xl": shape === "rounded",
                })}
              />
            </div>
            {/* background shape */}
            {shape === "shapes" && (
              <m.div
                viewport={{
                  once: true,
                  amount: "all",
                  margin: "-20px",
                }}
                initial={{ opacity: 0, x: reverse ? -2.5 : 2.5, y: -2.5 }}
                whileInView={{ opacity: 1, x: 0, y: 0 }}
                transition={transition}
                className={clsx("absolute inset-0 flex", {
                  "justify-start": reverse,
                  "justify-end": !reverse,
                })}
              >
                <div
                  className={clsx(
                    "relative h-full max-h-[20rem] w-full rounded-b-3xl md:-top-12 lg:max-h-[32rem]",
                    {
                      "rounded-tr-3xl rounded-tl-6xl md:-left-12 md:rounded-br-6xl":
                        reverse,
                      "rounded-tr-6xl rounded-tl-3xl md:-right-12 md:rounded-bl-6xl":
                        !reverse,
                      "bg-purple": color === "purple",
                      "bg-pink": color === "pink",
                      "bg-teal": color === "teal",
                      "bg-yellow": color === "yellow",
                    }
                  )}
                />
              </m.div>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

ImageCopy.defaultProps = {
  color: "teal",
  reverse: false,
  shape: "shapes",
};

export default ImageCopy;
